<template>
    <div class="mb-3"> 
        <section class="hero has-border-bottom-orange-1 welcome is-small">
            <div class="p-2">
                <div class="">
                    <h1 class="title is-size-6">
                        <i class="fas fa-newspaper is-text-palete-orange-1 mr-1"></i>
                        <label class="newsfeed-switch ml-1">
                            <input type="checkbox" v-model="is_today" class=""> 
							<!-- in change -> 1 or 0 -->
                            <span class="newsfeed-slider round switch-border-orange"></span>
                        </label>
                        <span>
                            {{is_today ? "Today's" : 'All'}}
                        </span>
                        {{$t("newsfeed") }}
                        <i @click.prevent="$parent.getMyModuleData('newsfeed')" id="newsfeed" class="fas fa-sync-alt is-pulled-right is-clickable"></i>
                    </h1>
                </div>
            </div>
        </section>
            <div class="table-wrapper mb-4 has-mobile-cards custom_scrollbar-1 nf-pending-height card">
                <table v-if="get_dashboard_newsfeed.length > 0" class="table dashboard-table custom_scrollbar-1  is-striped  is-narrow is-fullwidth is-size-7 ">
                    <thead class="">
                    <tr>
                        <th width="30px" draggable="false" class="is-sortable is-unselectable is-current-sort"> </th>
                        <th draggable="false" class="is-sortable is-unselectable" width="80px">
                            <span class="is-relative">
                                {{$t("type_change") }}
                            </span>
                        </th>
                        <th draggable="false" class="is-sortable is-unselectable" width="200px">
                            <span class="is-relative">
                                Project
                            </span>
                        </th>
                        <th draggable="false" class="is-sortable is-unselectable" width="180px">
                            <span class="is-relative">
                                {{$t("start_date") }} - {{$t("end_date") }}
                            </span>
                        </th>
                        <th draggable="false" class="is-sortable is-unselectable">
                            <span class="is-relative">
                                Comment
                            </span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(nf, index) in get_dashboard_newsfeed" :key="index" draggable="false" class="nf-pending-table">
                            <td v-if="nf.feed_type == 'chat_message'" :title="$t('nf_chat_message_' + nf.priority)" class="has-text-centered">
                                
                                <i v-if="nf.priority == 0"  class="fas fa-lg fa-comment normal_priority"></i>
                                <i v-else-if="nf.priority == 1"  class="fas fa-lg fa-comment medium_priority"></i>
                                <i v-else-if="nf.priority == 2"  class="fas fa-lg fa-comment high_priority"></i>
                                
                            </td>
                            <td v-else-if="nf.feed_type == 'release_message'" :title="$t('nf_release_message_' + nf.priority)">
                                <i v-if="nf.priority == 0" style="color:orange" class="fas fa-lg fa-clock"></i>
                                <i v-else-if="nf.priority == 1" style="color:green" class="fas fa-lg fa-check-circle"></i>
                            </td>
                            <td v-if="nf.feed_type == 'chat_message'" :title="$t('nf_release_message_' + nf.priority)">
                                <span :class="['is-link']" class="tag is-small has-background-success-dark has-text-light" 
                                    v-if="nf.priority == 0">{{ $t(nf.feed_type) }}</span>
                                <span :class="['is-link']" class="tag is-small has-background-warning-dark has-text-light" v-if="nf.priority == 1">{{ $t(nf.feed_type) }}</span>
                                <span :class="['is-link']" class="tag is-small has-background-danger-dark has-text-white" v-if="nf.priority == 2">{{ $t(nf.feed_type) }}</span>
                            </td>
                            <td v-else-if="nf.feed_type == 'release_message'">
                                <span class="tag is-small is-info" v-if="nf.priority == 0">
                                    {{ $t("release_message_0") }}
                                </span>
                                <span class="tag is-small is-success" v-if="nf.priority == 1">
                                    {{ $t("release_message_1") }}
                                </span>
                            </td>
                            <td>
                                <p>
                                    {{nf.ej_number}}
                                </p>
                                {{nf.name}}
                            </td>
                            <td>
                                <p class="m-0">
                                    <span v-if="nf.start_date">
                                        {{nf.start_date |customdate}}
                                    </span>
                                    <span v-else class="has-text-grey-light">
                                        TBD
                                    </span>
                                </p>
                                <p class="m-0 has-text-right">
                                    {{nf.end_date |customdate}}
                                </p>
                            </td>
                            <td>
                                <p class="is-flex">
                                    <span v-if="nf.created_by" style="font-size:9px;" >
                                        {{nf.created_by}} 
                                        <br>
                                    </span>
                                    <span class="has-text-grey-light ml-auto" style="font-size:9px;">
                                        {{nf.feed_created_at | local-datetime}}</span>
                                </p>
                                <p>
                                    {{nf.text | truncate(100, '...')}}
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <small v-else class="ml-2 has-text-grey-light" >
                    No data
                </small>
            </div>
            <!-- <footer v-if=" get_dashboard_newsfeed.length && get_dashboard_newsfeed.length != this.$parent.modules_data.my_newsfeed.length " class="card-footer border-none p-1 ">
                <a @click.prevent="toggle_limit()" href="#" class="card-footer-item p-0 has-text-white">{{$t("load_more")}}</a>
            </footer> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            limit: 6,
            default_limit: 6,
            is_today: false
        }
    },
    computed: {
        get_dashboard_newsfeed(){ return this.$parent.modules_data.my_newsfeed.filter(nf => {
            if(this.$parent.selected_project_id != 0 && this.$parent.selected_project_id == nf.project_id){
                console.table(this.$parent.selected_project_id, nf.project_id)
                return nf
            } 
            else if(!this.$parent.selected_project_id) return nf

            })
            .filter((obj) =>{
                if(this.$parent.tetris_search_text != ''){
                    return JSON.stringify(obj).toLowerCase().includes(this.$parent.tetris_search_text.toLowerCase())
                } else return obj
            })
            .filter((obj1) => {
                var today = new Date()
                var obj_date = new Date(obj1.feed_created_at)
                
                if(this.is_today && this.$moment(obj_date).isSame(today, "day")){
                    return true 
                } 
                else if(!this.is_today ) {
                    return true
                }
            })
        
        },
    },
    methods: {
        toggle_limit(){
            this.limit =  this.limit + this.default_limit
        },
    }
    
}
</script>

<style>
    /* Slider */
    .newsfeed-slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #1f2d3b;
        transition: .4s;
        height: 15px;
        width: 35px;
    }

    .newsfeed-slider:before {
        position: absolute;
        content: "";
        height: 9px;
        width: 9px;
        left: 3px;
        bottom: 3px;
        background-color: white;
        transition: .4s;
        margin-right:10px;
    }

    input:checked + .newsfeed-slider {
        background-color: #ddb03b;
    }

        input:focus + .newsfeed-slider {
        box-shadow: 0 0 0 2px rgba(21, 156, 228, 0.7);
        outline: none;
    }

    input:checked + .newsfeed-slider:before {
        transform: translateX(20px);
    }

    .newsfeed-slider.round {
        border-radius: 34px;
        }

    .newsfeed-slider.round:before {
        border-radius: 50%;
    }
    .switch-border-orange{
        box-shadow: 0px 0px 1px 1px #ddb03b;
    }
    .newsfeed-switch {
        position: relative;
        display: inline-block;
        width: 40px;
        /* height: 26px; */
    }
    .newsfeed-switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }
</style>
